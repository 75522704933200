import {initialState} from "./dictionnaire";
export const checkMot = (str) => {
    /**
     * choix de la langue:
     */
    if (initialState.dico_langues[str]!== undefined){
        if (initialState.dico_langues[str][initialState.langueSite] !== undefined){ // false, but the key exists!
            return initialState.dico_langues[str][initialState.langueSite];
        }else {
            console.warn(str, ' n\'existe pas en', initialState.langueSite);
            return initialState.dico_langues[str]["fr"];
        }
    }else {
        console.warn('/!\\',str, ' n\'existe pas dans le dictionnaire de langues. /!\\');
        return str;
    }
}