export const initialState = {
    "langueSite": "fr",
    "dico_langues": {
        "login": {
            "fr": "connexion",
            "en-gb": "login",
            "en-us": "login",
        },
        "username_email": {
            "fr": "Nom utilisateur / email",
            "en-gb": "Username / email",
            "en-us": "Username / email",
        },
        "password": {
            "fr": "Mot de passe",
            "en-gb": "Password",
            "en-us": "Password",
        },
        "search_organization_application_gateway_or_device": {
            "fr": "Recherche par organisation, application, passerelle ou appareil",
            "en-gb": "Search organization, application, gateway or device",
            "en-us": "Search organization, application, gateway or device",
        },
        "logout": {
            "fr": "Déconnexion",
            "en-gb": "Logout",
            "en-us": "Logout",
        },
        "change_password": {
            "fr": "Changer de mot de passe",
            "en-gb": "Change password",
            "en-us": "Change password",
        },
        "dashboard": {
            "fr": "Tableau de bord",
            "en-gb": "Dashboard",
            "en-us": "Dashboard",
        },
        "network_servers": {
            "fr": "Serveurs de réseau",
            "en-gb": "Network-servers",
            "en-us": "Network-servers",
        },
        "gateway_profiles": {
            "fr": "Profils de passerelle",
            "en-gb": "Gateway-profiles",
            "en-us": "Gateway-profiles",
        },
        "Organizations": {
            "fr": "Organisations",
            "en-gb": "Organizations",
            "en-us": "Organizations",
        },
        "all_users": {
            "fr": "Tous les utilisateurs",
            "en-gb": "All users",
            "en-us": "All users",
        },
        "api_keys": {
            "fr": "Clés API",
            "en-gb": "API keys",
            "en-us": "API keys",
        },
        "org_dashboard": {
            "fr": "Org. Tableau de bord",
            "en-gb": "Org. dashboard",
            "en-us": "Org. dashboard",
        },
        "org_users": {
            "fr": "Org. Utilisateurs",
            "en-gb": "Org. users",
            "en-us": "Org. users",
        },
        "org_api_keys": {
            "fr": "Org. Clés API",
            "en-gb": "Org. API keys",
            "en-us": "Org. API keys",
        },
        "dervice_profiles": {
            "fr": "Profils de service",
            "en-gb": "Service-profiles",
            "en-us": "Service-profiles",
        },
        "device_profiles": {
            "fr": "Profils d'appareils",
            "en-gb": "Device-profiles",
            "en-us": "Device-profiles",
        },
        "gateways": {
            "fr": "Passerelles",
            "en-gb": "Gateways",
            "en-us": "Gateways",
        },
        "applications": {
            "fr": "Applications",
            "en-gb": "Applications",
            "en-us": "Applications",
        },
        "showing": {
            "fr": "Affichage",
            "en-gb": "Showing",
            "en-us": "Showing",
        },
        "of": {
            "fr": "de",
            "en-gb": "of",
            "en-us": "of",
        },
        "Email": {
            "fr": "Email",
            "en-gb": "Email",
            "en-us": "Email",
        },
        "Active": {
            "fr": "Active",
            "en-gb": "Active",
            "en-us": "Active",
        },
        "Admin": {
            "fr": "Admin",
            "en-gb": "Admin",
            "en-us": "Admin",
        },
        "Create": {
            "fr": "Créé",
            "en-gb": "Create",
            "en-us": "Create",
        },
        "Organization users": {
            "fr": "Gestion des utilisateurs",
            "en-gb": "Organization users",
            "en-us": "Organization users",
        },
        "Delete": {
            "fr": "Supprimer",
            "en-gb": "Delete",
            "en-us": "Delete",
        },
        "Add": {
            "fr": "Ajouter",
            "en-gb": "Add",
            "en-us": "Add",
        },
        "Add user": {
            "fr": "Ajouter l'utilisateur",
            "en-gb": "Add user",
            "en-us": "Add user",
        },
        "Users": {
            "fr": "Utilisateurs",
            "en-gb": "Users",
            "en-us": "Users",
        },
        "Never seen": {
            "fr": "Jamais vu",
            "en-gb": "Never seen",
            "en-us": "Never seen",
        },
        "Inactive": {
            "fr": "Inactif",
            "en-gb": "Inactive",
            "en-us": "Inactive",
        },
        "Active devices": {
            "fr": "Appareils actifs",
            "en-gb": "Active devices",
            "en-us": "Active devices",
        },
        "Password": {
            "fr": "Mot de passe",
            "en-gb": "Password",
            "en-us": "Password",
        },
        "Create user": {
            "fr": "Utilisateurs",
            "en-gb": "Create user",
            "en-us": "Create user",
        },
        "Are you sure you want to delete this user?": {
            "fr": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
            "en-gb": "Are you sure you want to delete this user?",
            "en-us": "Are you sure you want to delete this user?",
        },
        "Change password": {
            "fr": "Changer de mot de passe",
            "en-gb": "Change password",
            "en-us": "Change password",
        },
        "Update password": {
            "fr": "Mise à jour du mot de passe",
            "en-gb": "Update password",
            "en-us": "Update password",
        },
        "Device data-rate usage": {
            "fr": "Utilisation du débit de données de l'appareil",
            "en-gb": "Device data-rate usage",
            "en-us": "Device data-rate usage",
        },
        "Active gateways": {
            "fr": "Passerelles actives",
            "en-gb": "Active gateways",
            "en-us": "Active gateways",
        },
        "Gateways": {
            "fr": "Passerelles",
            "en-gb": "Gateways",
            "en-us": "Gateways",
        },
        "Network-servers": {
            "fr": "Serveurs de réseau",
            "en-gb": "Network-servers",
            "en-us": "Network-servers",
        },
        "Name": {
            "fr": "Nom",
            "en-gb": "Name",
            "en-us": "Name",
        },
        "Server": {
            "fr": "Serveur",
            "en-gb": "Server",
            "en-us": "Server",
        },
        "Notez également que le profil de passerelle ne modifie pas le comportement des appareils. Pour configurer le plan de canaux qui doit être utilisé par les appareils, mettez à jour la configuration du serveur de réseau ChirpStack.": {
            "fr": "Notez également que le profil de passerelle ne modifie pas le comportement des appareils. Pour configurer le plan de canaux qui doit être utilisé par les appareils, mettez à jour la configuration du serveur de réseau ChirpStack.",
            "en-gb": "Notez également que le profil de passerelle ne modifie pas le comportement des appareils. Pour configurer le plan de canaux qui doit être utilisé par les appareils, mettez à jour la configuration du serveur de réseau ChirpStack.",
            "en-us": "Notez également que le profil de passerelle ne modifie pas le comportement des appareils. Pour configurer le plan de canaux qui doit être utilisé par les appareils, mettez à jour la configuration du serveur de réseau ChirpStack.",
        },
        "Please note that this feature is optional and only works in combination with the ChirpStack Concentratord component.": {
            "fr": "Veuillez noter que cette fonction est facultative et ne fonctionne qu'en combinaison avec le composant ChirpStack Concentratord.",
            "en-gb": "Please note that this feature is optional and only works in combination with the ChirpStack Concentratord component.",
            "en-us": "Please note that this feature is optional and only works in combination with the ChirpStack Concentratord component.",
        },
        "Add network-servers": {
            "fr": "Ajouter des serveurs de réseau",
            "en-gb": "Add network-servers",
            "en-us": "Add network-servers",
        },
        "Are you sure you want to delete this network-server?": {
            "fr": "Êtes-vous sûr de vouloir supprimer ce serveur de réseau ?",
            "en-gb": "Are you sure you want to delete this network-server?",
            "en-us": "Are you sure you want to delete this network-server?",
        },
        "The only purpose of a Gateway Profile is to (re)configure one or multiple gateways with the configuration properties that are set by the Gateway Profile.": {
            "fr": "Le seul but d'un profil de passerelle est de (re)configurer une ou plusieurs passerelles avec les propriétés de configuration définies par le profil de passerelle.",
            "en-gb": "The only purpose of a Gateway Profile is to (re)configure one or multiple gateways with the configuration properties that are set by the Gateway Profile.",
            "en-us": "The only purpose of a Gateway Profile is to (re)configure one or multiple gateways with the configuration properties that are set by the Gateway Profile.",
        },
        "When the ChirpStack Network Server detects that the configuration of a gateway is out-of-sync with its Gateway Profile, it will push a configuration command to the gateway in order to update its configuration.": {
            "fr": "Lorsque le serveur de réseau ChirpStack détecte que la configuration d'une passerelle n'est pas synchronisée avec son profil de passerelle, il envoie une commande de configuration à la passerelle afin de mettre à jour sa configuration.",
            "en-gb": "When the ChirpStack Network Server detects that the configuration of a gateway is out-of-sync with its Gateway Profile, it will push a configuration command to the gateway in order to update its configuration.",
            "en-us": "When the ChirpStack Network Server detects that the configuration of a gateway is out-of-sync with its Gateway Profile, it will push a configuration command to the gateway in order to update its configuration.",
        },
        "Close": {
            "fr": "Fermer",
            "en-gb": "Close",
            "en-us": "Close",
        },
        "Network - server": {
            "fr": "Serveur de réseau",
            "en-gb": "Network - server",
            "en-us": "Network - server",
        },
        "Gateway-profiles": {
            "fr": "Profils de passerelle",
            "en-gb": "Gateway-profiles",
            "en-us": "Gateway-profiles",
        },
        "Gateway Profile help": {
            "fr": "Profil de la passerelle - Aide",
            "en-gb": "Gateway Profile help",
            "en-us": "Gateway Profile help",
        },
        "Are you sure you want to delete this api key?": {
            "fr": "Êtes-vous sûr de vouloir supprimer cette clé d'accès ?",
            "en-gb": "Are you sure you want to delete this api key?",
            "en-us": "Are you sure you want to delete this api key?",
        },
        "Global API keys": {
            "fr": "Clés d'API globales",
            "en-gb": "Global API keys",
            "en-us": "Global API keys",
        },
        "Create API key": {
            "fr": "Créer une clé API",
            "en-gb": "Create API key",
            "en-us": "Create API key",
        },
        "Service-profiles": {
            "fr": "Profils de service",
            "en-gb": "Service-profiles",
            "en-us": "Service-profiles",
        },
        "ChirpStack Application Server isn't connected to a ChirpStack Network Server network-server. Did you know that ChirpStack Application Server can connect to multiple ChirpStack Network Server instances, e.g. to support multiple regions?": {
            "fr": "Le serveur d'application ChirpStack n'est pas connecté à un serveur réseau ChirpStack Network Server. Saviez-vous que le serveur d'application ChirpStack peut se connecter à plusieurs instances de serveur de réseau ChirpStack, par exemple pour prendre en charge plusieurs régions ?",
            "en-gb": "ChirpStack Application Server isn't connected to a ChirpStack Network Server network-server. Did you know that ChirpStack Application Server can connect to multiple ChirpStack Network Server instances, e.g. to support multiple regions?",
            "en-us": "ChirpStack Application Server isn't connected to a ChirpStack Network Server network-server. Did you know that ChirpStack Application Server can connect to multiple ChirpStack Network Server instances, e.g. to support multiple regions?",
        },
        "Add a network-server?": {
            "fr": "Ajouter un serveur de réseau ?",
            "en-gb": "Add a network-server?",
            "en-us": "Add a network-server?",
        },
        "Would you like to connect to a network-server now?": {
            "fr": "Voulez-vous vous connecter à un serveur de réseau maintenant ?",
            "en-gb": "Would you like to connect to a network-server now?",
            "en-us": "Would you like to connect to a network-server now?",
        },
        "Organization API keys": {
            "fr": "Clés d'API de gestion",
            "en-gb": "Organization API keys",
            "en-us": "Organization API keys",
        },
        "Dismiss": {
            "fr": "Rejeter",
            "en-gb": "Dismiss",
            "en-us": "Dismiss",
        },
        "Add network-server": {
            "fr": "Ajouter un serveur de réseau",
            "en-gb": "Add network-server",
            "en-us": "Add network-server",
        },
        "Are you sure you want to delete this service-profile?": {
            "fr": "Êtes-vous sûr de vouloir supprimer ce profil de service ?",
            "en-gb": "Are you sure you want to delete this service-profile?",
            "en-us": "Are you sure you want to delete this service-profile?",
        },
        "Device-profiles": {
            "fr": "Profils des appareils",
            "en-gb": "Device-profiles",
            "en-us": "Device-profiles",
        },
        "Create service-profile": {
            "fr": "Créer un profil de service",
            "en-gb": "Create service-profile",
            "en-us": "Create service-profile",
        },
        "Add a service-profile?": {
            "fr": "Ajouter un profil de service ?",
            "en-gb": "Add a service-profile?",
            "en-us": "Add a service-profile?",
        },
        "The selected organization does not have a service-profile yet. A service-profile connects an organization to a network-server and defines the features that an organization can use on this network-server.": {
            "fr": "L'organisation sélectionnée n'a pas encore de profil de service. Un profil de service relie une organisation à un serveur de réseau et définit les fonctionnalités qu'une organisation peut utiliser sur ce serveur de réseau.",
            "en-gb": "The selected organization does not have a service-profile yet. A service-profile connects an organization to a network-server and defines the features that an organization can use on this network-server.",
            "en-us": "The selected organization does not have a service-profile yet. A service-profile connects an organization to a network-server and defines the features that an organization can use on this network-server.",
        },
        "Would you like to create a service-profile?": {
            "fr": "Souhaitez-vous créer un profil de service ?",
            "en-gb": "Would you like to create a service-profile?",
            "en-us": "Would you like to create a service-profile?",
        },
        "Gateway details": {
            "fr": "Détails de la passerelle",
            "en-gb": "Gateway details",
            "en-us": "Gateway details",
        },
        "Gateway configuration": {
            "fr": "Configuration de la passerelle",
            "en-gb": "Gateway configuration",
            "en-us": "Gateway configuration",
        },
        "Certificate": {
            "fr": "Certificat",
            "en-gb": "Certificate",
            "en-us": "Certificate",
        },
        "Gateway discovery": {
            "fr": "Découverte de la passerelle",
            "en-gb": "Gateway discovery",
            "en-us": "Gateway discovery",
        },
        "Live LoRaWAN frames": {
            "fr": "Trames LoRaWAN en direct",
            "en-gb": "Live LoRaWAN frames",
            "en-us": "Live LoRaWAN frames",
        },
        "Are you sure you want to delete this gateway?": {
            "fr": "Êtes-vous sûr de vouloir supprimer cette passerelle ?",
            "en-gb": "Are you sure you want to delete this gateway?",
            "en-us": "Are you sure you want to delete this gateway?",
        },
        "Gateway ID": {
            "fr": "ID de la passerelle",
            "en-gb": "Gateway ID",
            "en-us": "Gateway ID",
        },
        "Network server": {
            "fr": "Serveur de réseau",
            "en-gb": "Network server",
            "en-us": "Network server",
        },
        "Gateway activity (30d)": {
            "fr": "Activité de passerelle (30j)",
            "en-gb": "Gateway activity (30d)",
            "en-us": "Gateway activity (30d)",
        },
        "Are you sure you want to delete this multicast-group?": {
            "fr": "Êtes-vous sûr de vouloir supprimer ce groupe de multidiffusion ?",
            "en-gb": "Are you sure you want to delete this multicast-group?",
            "en-us": "Are you sure you want to delete this multicast-group?",
        },
        "Description": {
            "fr": "Description",
            "en-gb": "Description",
            "en-us": "Description",
        },
        "Service-profile": {
            "fr": "Profil de service",
            "en-gb": "Service-profile",
            "en-us": "Service-profile",
        },
        "Add a device-profile?": {
            "fr": "Ajouter un profil d'appareil ?",
            "en-gb": "Add a device-profile?",
            "en-us": "Add a device-profile?",
        },
        "The selected application does not have access to any device-profiles. A device-profile defines the capabilities and boot parameters of a device. You can create multiple device-profiles for different kind of devices.": {
            "fr": "L'application sélectionnée n'a accès à aucun profil de périphérique. Un profil d'appareil définit les capacités et les paramètres de démarrage d'un appareil. Vous pouvez créer plusieurs profils de périphérique pour différents types de périphériques.",
            "en-gb": "The selected application does not have access to any device-profiles. A device-profile defines the capabilities and boot parameters of a device. You can create multiple device-profiles for different kind of devices.",
            "en-us": "The selected application does not have access to any device-profiles. A device-profile defines the capabilities and boot parameters of a device. You can create multiple device-profiles for different kind of devices.",
        },
        "Would you like to create a device-profile?": {
            "fr": "Souhaitez-vous créer un profil d'appareil ?",
            "en-gb": "Would you like to create a device-profile?",
            "en-us": "Would you like to create a device-profile?",
        },
        "Are you sure you want to delete this device?": {
            "fr": "Êtes-vous sûr de vouloir supprimer cet appareil ?",
            "en-gb": "Are you sure you want to delete this device?",
            "en-us": "Are you sure you want to delete this device?",
        },
        "Details": {
            "fr": "Détails",
            "en-gb": "Details",
            "en-us": "Details",
        },
        "Configuration": {
            "fr": "Configuration",
            "en-gb": "Configuration",
            "en-us": "Configuration",
        },
        "Keys (OTAA)": {
            "fr": "Clés (OTAA)",
            "en-gb": "Keys (OTAA)",
            "en-us": "Keys (OTAA)",
        },
        "Activation": {
            "fr": "Activation",
            "en-gb": "Activation",
            "en-us": "Activation",
        },
        "Device data": {
            "fr": "Données de l'appareil",
            "en-gb": "Device data",
            "en-us": "Device data",
        },
        "Applications": {
            "fr": "Applications",
            "en-gb": "Applications",
            "en-us": "Applications",
        },
        "Devices": {
            "fr": "Appareils",
            "en-gb": "Devices",
            "en-us": "Devices",
        },
        "Multicast groups": {
            "fr": "Groupes de multidiffusion",
            "en-gb": "Multicast groups",
            "en-us": "Multicast groups",
        },
        "Application configuration": {
            "fr": "Configuration de l'application",
            "en-gb": "Application configuration",
            "en-us": "Application configuration",
        },
        "Integrations": {
            "fr": "Intégrations",
            "en-gb": "Integrations",
            "en-us": "Integrations",
        },
        "Are you sure you want to delete this application? This will also delete all devices part of this application.": {
            "fr": "Êtes-vous sûr de vouloir supprimer cette application ? Cette opération supprimera également tous les appareils faisant partie de cette application.",
            "en-gb": "Are you sure you want to delete this application? This will also delete all devices part of this application.",
            "en-us": "Are you sure you want to delete this application? This will also delete all devices part of this application.",
        },
        "Display name": {
            "fr": "Nom d'affichage",
            "en-gb": "Display name",
            "en-us": "Display name",
        },
        "Can have gateways": {
            "fr": "Possibilité d'avoir des passerelles",
            "en-gb": "Can have gateways",
            "en-us": "Can have gateways",
        },
        "ID": {
            "fr": "ID",
            "en-gb": "ID",
            "en-us": "ID",
        },
    }
}


