import { createMuiTheme } from "@material-ui/core/styles";


const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#ffffff',
            main: '#3c3935',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        common: {
            light: '#ffffff',
            main: '#3c3935',
            white: '#d3cdc6'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#ebebeb',
            main: '#a6163f',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#eeb214',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});
  
export default theme;
